import { ConversationStatusType, ProjectStatus } from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import _ from 'lodash'
import { storeToRefs } from 'pinia'
export const disabledMessageEntity = (itemObj: any) => {
    const projectStore = useProjectStore()
    const { projectInfo } = storeToRefs(projectStore)
    if (_.isEmpty(projectInfo.value)) {
        return false
    }
    const { status } = itemObj
    if (itemObj.hasOwnProperty('section_id')) {
        
        if ([ProjectStatus.End].includes(projectInfo.value.status)) {
            
            return true
        } else if (status === ConversationStatusType.SENDED) {
            
            return true
        } else {
            return false
        }
    } else {
        
        return [ProjectStatus.End, ProjectStatus.Published, ProjectStatus.Start].includes(projectInfo.value.status)
    }
}
